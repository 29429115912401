


























































































import {Component, Vue, Prop} from 'vue-property-decorator';
import store from '@/services/store';
import { makeToast } from '@/modules/budget-request/components/js/some-functions';
import AlterationI18nMixin from "@/modules/budget/alteration/mixins/alteration-i18n-mixin";


@Component({
    components: {}
})
export default class CIncomeAddItemModal extends AlterationI18nMixin {
    @Prop({
        required: true,
        default: false
    })
    private show!: boolean;

    @Prop({
        required: false,
        default: []
    })
    private dataTable!: any[];

    private showModal = false;

    private get isReadyToAdd(): boolean {
        if (!this.curKat || !this.curCls || !this.curPcl || !this.curSpf.length) {
            return false;
        }
        return true;
    }

    // ------------- kat -------------------
    private katBase: any[] = [];
    private curKat: any | null = null;

    private async loadKat() {
        this.katBase = await this.loadDictEbkDoh(0);
        if  (this.katBase.length) {
            this.curKat = this.setNameLang(this.katBase[0], 'kat');
        }
        this.chgKat();
    }

    private get katLst() {
        const result: any[] = [];
        for (const el of this.katBase) {
            result.push(this.setNameLang(el, 'kat'));
        }

        if (this.curKat !== null) {
            this.curKat = this.setNameLang(this.curKat, 'kat');
        }
        return result;
    }

    private chgKat() {
        this.curCls = null;
        this.curPcl = null;
        this.curSpf = [];
        this.clsBase = [];
        this.pclBase = [];
        this.spfBase = [];
        if (this.curKat) {
            this.loadCls(this.curKat.id);
        } else {
            this.loadCls(null);
        }
    }

    // ----------- cls -----------------
    private isLoadingCls = false;
    private clsBase: any[] = [];
    private curCls: any | null  = null;

    private async loadCls(id: number | null) {
        this.curCls = null;
        this.isLoadingCls = true;
        this.clsBase = await this.loadDictEbkDoh(id);
        if  (this.clsBase.length) {
            this.curCls = this.setNameLang(this.clsBase[0], 'clss');
        } else {
            this.curCls = null;
        }
        this.isLoadingCls = false;
        this.chgCls();
    }

    private get clsLst() {
        const result: any[] = [];
        for (const el of this.clsBase) {
            result.push(this.setNameLang(el, 'clss'));
        }

        if (this.curCls !== null) {
            this.curCls = this.setNameLang(this.curCls, 'clss');
        }
        return result;
    }

    private chgCls() {
        if (this.curCls) {
            this.loadPcl(this.curCls.id);
        } else {
            this.loadPcl(null);
        }
    }

    // ------------ pcl ------------------
    private isLoadingPcl = false;
    private pclBase: any[] = [];
    private curPcl: any | null  = null;

    private async loadPcl(id: number | null) {
        this.curPcl = null;
        this.isLoadingPcl = true;
        this.pclBase = await this.loadDictEbkDoh(id);
        if  (this.pclBase.length) {
            this.curPcl = this.setNameLang(this.pclBase[0], 'pcl');
        } else {
            this.curPcl = null;
        }
        this.isLoadingPcl = false;
        this.chgPcl();
    }

    private get pclLst() {
        const result: any[] = [];
        for (const el of this.pclBase) {
            result.push(this.setNameLang(el, 'pcl'));
        }

        if (this.curPcl !== null) {
            this.curPcl = this.setNameLang(this.curPcl, 'pcl');
        }
        return result;
    }

    private chgPcl() {
        if (this.curPcl) {
            this.loadSpf(this.curPcl.id);
        } else {
            this.loadSpf(null);
        }
    }

    // ------------ spf -----------------
    private isLoadingSpf = false;
    private spfBase: any[] = [];
    private curSpf: any[]  = [];

    private async loadSpf(id: number | null) {
        this.curSpf = [];
        this.isLoadingSpf = true;
        this.spfBase = await this.loadDictEbkDoh(id);
        if  (this.spfBase.length) {
            this.curSpf = [this.setNameLang(this.spfBase[0], 'spf')]
        } else {
            this.curSpf = [];
        }
        this.isLoadingSpf = false;
    }

    private get spfLst() {
        const result: any[] = [];
        for (const el of this.spfBase) {
            result.push(this.setNameLang(el, 'spf'));
        }
        return result;
    }

    // -----------------------------------

    private mounted() {
        this.$watch('show', () => {
            if (this.show) {
                this.showModal = this.show;
            }
            this.$emit('shown');
        });
        this.loadKat();
    }



    private async loadDictEbkDoh(id: number | null) {
        if (id === null) {
            return [];
        }
        let result: any = [];
        try {
            const response: any = await fetch(`/api-py/get-dict-doh-childs-by-parents-code/${id}`);
            result = await response.json();
            return result;
        } catch (error) {
            makeToast(this, 'danger', `Ошибка get-dict-doh-childs-by-parent (${id})`, (error as Error).toString());
            return [];
        }
    }

    private setNameLang(obj: any, codeName?: any | null): any {
        let txt = obj['name_' + this.$i18n.locale];
        if (txt === undefined) {
            txt = obj.name_ru;
        }
        if (codeName !== undefined && codeName !== null && obj[codeName] !== undefined) {
            txt = obj[codeName] + ' - ' + txt;
        }
        const el = Object.assign({}, obj);
        el.name = txt;
        return el;
    }

    private addClk() {
        const resArr = [];
        for (const elSpf of this.curSpf) {
            const resObj: any = {
                kat: this.curKat.kat,
                kat_name_ru: this.curKat.name_ru,
                kat_name_en: this.curKat.name_ru,
                kat_name_kk: this.curKat.name_kk,
                collapse: false,
                totalSum: [],
                child_list: [{
                    kat: this.curKat.kat,
                    cls: this.curCls.clss,
                    clss: this.curCls.clss,
                    cls_name_ru: this.curCls.name_ru,
                    cls_name_en: this.curCls.name_ru,
                    cls_name_kk: this.curCls.name_kk,
                    collapse: false,
                    totalSum: [],
                    child_list: [{
                        kat: this.curKat.kat,
                        cls: this.curCls.clss,
                        clss: this.curCls.clss,
                        pcl: this.curPcl.pcl,
                        pcl_name_ru: this.curPcl.name_ru,
                        pcl_name_en: this.curPcl.name_ru,
                        pcl_name_kk: this.curPcl.name_kk,
                        collapse: false,
                        totalSum: [],
                        child_list: [{
                            kat: this.curKat.kat,
                            clss: this.curCls.clss,
                            cls: this.curCls.clss,
                            pcl: this.curPcl.pcl,
                            spf: elSpf.spf,
                            spf_name_ru: elSpf.name_ru,
                            spf_name_en: elSpf.name_ru,
                            spf_name_kk: elSpf.name_kk,
                            collapse: false
                        }],
                    }],
                }],
                clss: this.curCls.clss,
                pcl: this.curPcl.pcl,
                spf: elSpf.spf,
                newEl: true
            };

            for (let m = 0; m < 12; m++) {
                resObj[`month${m}`] = 0;
                resObj['totalSum'][`month${m}`] = 0;
                resObj['child_list'][0][`month${m}`] = 0;
                resObj['child_list'][0]['totalSum'][`month${m}`] = 0;
                resObj['child_list'][0]['child_list'][0][`month${m}`] = 0;
                resObj['child_list'][0]['child_list'][0]['totalSum'][`month${m}`] = 0;
                resObj['child_list'][0]['child_list'][0]['child_list'][0][`month${m}`] = 0;
            }
            let fl = false;
            if (this.dataTable) {
                for (let i = 0; i < this.dataTable.length; i++) {
                    const elKat = this.dataTable[i];
                    if (elKat.kat === resObj.kat) {
                        for (let i = 0; i < elKat['child_list'].length; i++) {
                            const elCls = elKat['child_list'][i];
                            if (elCls.kat === resObj.kat && elCls.clss === resObj.clss) {
                                for (let i = 0; i < elCls['child_list'].length; i++) {
                                    const elPcl = elCls['child_list'][i];
                                    if (elPcl.kat === resObj.kat && elPcl.clss === resObj.clss  && elPcl.pcl === resObj.pcl) {
                                        for (let i = 0; i < elPcl['child_list'].length; i++) {
                                            const elSpf = elPcl['child_list'][i];
                                            if (elSpf.kat === resObj.kat && elSpf.clss === resObj.clss && elSpf.pcl === resObj.pcl && elSpf.spf === resObj.spf) {
                                                fl = true;
                                                makeToast(this, 'warning', 'Добавление элемента', `Элемент со спецификой ${resObj.spf} уже существует!`);
                                                break;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            if (!fl) { resArr.push(resObj); }
        }
        if (resArr.length) {
            this.$emit('addItemClk', resArr);
            this.showModal = false;
        }
    }

}
