<template>
    <div>
        <div>
            <div class="filter-container">
                <div class="left-content">
                    <b-dropdown class="filter-dropdown" variant="default" ref="drop">
                        <template #button-content>
                            <span class="lc"><i class="icon icon-filter"></i> {{ setText('filter') }} </span><span class="rc"><i
                            class="icon icon-keyboard"></i></span>
                        </template>
                        <div>
                            <div class="top-content">
                                <span>{{ setText('filter_params') }}</span>
                                <i class="icon icon-close" @click="$refs.drop.hide(true)"></i>
                            </div>
                            <div class="filter-block">
                                <b-form-group :label="setText('project')">
                                    <div>
                                        <b-form-checkbox v-model="project"  @input="loadData()" switch/>
                                    </div>
                                </b-form-group>
                            </div>
                            <!--------------- Фмнансовый год ------------------->
                            <div class="filter-block">
                                <b-form-group :label="setText('fin_year')">
                                    <multiselect v-show="year"
                                                 ref="bcPlanYear"
                                                 v-model="planPeriod"
                                                 track-by="name"
                                                 label="name"
                                                 :placeholder="setText('year_select')"
                                                 :options="year"
                                                 :searchable="false"
                                                 :allow-empty="false"
                                                 :show-labels="false">
                                    </multiselect>
                                </b-form-group>
                            </div>
                            <!---------------------data------------------------->
                            <div class="filter-block">
                                <b-form-group :label="setText('date')">
                                    <date-picker
                                        :placeholder="setText('date_select')"
                                        v-model="date"
                                        format = "YYYY-DD-MM"
                                        type="date"
                                        style="max-width: 1200px"
                                        @input="loadData"
                                        ref="filterDate"
                                        :append-to-body="false"
                                    />
                                </b-form-group>
                            </div>
                            <!---------------------Регион---------------------->
                            <div class="filter-block">
                                <b-form-group :label="setText('obl')">
                                    <multiselect
                                        v-model="currRegion"
                                        label="localText"
                                        :placeholder="setText('region_select')"
                                        :options="regionBase"
                                        :searchable="true"
                                        :allow-empty="false"
                                        :show-labels="false"
                                        ref="filterReg"
                                    />
                                </b-form-group>
                            </div>
                            <div class="filter-block">
                                <b-form-group :label="setText('mutually_redeeming')">
                                    <b-form-radio-group
                                        ref="bcVZK"
                                        v-model="vzk"
                                        :options="vzk_list"
                                    >
                                    </b-form-radio-group>
                                </b-form-group>
                            </div>
                            <div class="filter-block">
                                <b-button variant="primary" @click="loadData()">{{setText('apply')}}</b-button>
                            </div>
                        </div>
                    </b-dropdown>
                </div>
            </div>
        </div>
        <div class="actions-tab in-tab">
            <!---------------Форма для скачивания отчета--------------->
            <b-dropdown variant="link" right toggle-class="text-decoration-none" no-caret>
                <template #button-content>
                    <div class="btn-download">
                        <div>
                                    <span class="left">
                                        <i class="icon icon-download"></i> {{setText('download')}}
                                    </span>
                            <i class="icon icon-keyboard"></i>
                        </div>
                    </div>
                </template>
                <b-dropdown-item @click="download">{{setText('scorr_plan_inc')}}</b-dropdown-item>
            </b-dropdown>
        </div>
        <!--  хлебные крошки   -->
        <div class="filter-breadcrumb">
            <span class="item-breadcrumb" v-if="project" @click="openFilterByRef('bcProject')"> {{setText('project')}} </span>
            <span class="item-breadcrumb" v-if="date" @click="openFilterByRef('filterDate')"> {{ date | formatDate }} </span>
            <span class="item-breadcrumb" v-if="currRegion" @click="openFilterByRef('filterReg')"> {{ currRegion.localText}} </span>
            <span class="item-breadcrumb" @click="openFilterByRef('bcVZK')"> {{vzk_list[vzk].text }} </span>
        </div>
        <div class="top-table-line"></div>
        <inc-tree-table :table-data="budgetForm" :accessLevel="accessLevels"/>
        <loading
            :active="loading"
            is-full-screen
            spinner="bar-fade-scale"
            color="#6495ED"
        />
    </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import VueElementLoading from "vue-element-loading";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import {Ax} from "@/utils"
import moment from 'moment';
import IncTreeTable from "./tableDir/IncTreeTable";
import store from "@/services/store";
import AlterationI18nMixin from "@/modules/budget/alteration/mixins/alteration-i18n-mixin";
// расходы
export default {
    name: "incomeTable",
    components: { DatePicker, 'loading': VueElementLoading, IncTreeTable },
    mixins: [AlterationI18nMixin],
    props: [ "accessLevels", "userId" ],
    data() {
        return {
            date: new Date(),
            loading: false,
            regionBase: [],
            currRegion: null,
            budgetForm: [],
            vzk: 2,
            vzk_list: [
                {value: 0, text: this.$t('modules.budget_execution_alteration.WITHOUT')},
                {value: 1, text: this.$t('modules.budget_execution_alteration.ONLY')},
                {value: 2, text: this.$t('modules.budget_execution_alteration.WITH')}
            ],
            planPeriod: null,
            year: [],
            project: false,
        }
    },
    async mounted() {
        this.years(this, this.year);
    },
    computed: {
        locale(){
            return this.$i18n.locale;
        }},
    created() {
        this.loadRegions();
        this.$watch('locale', this.localeChange);
    },
    watch: {
        "accessLevels": function () {},
        "regionBase": function () {
            this.loading = true;
            this.currRegion = this.regionBase[0];
            if (['uf'].includes(this.accessLevels.position)) {
                setTimeout(() => this.loadData(), 1000);
            }
            this.loading = false;
        }
    },
    methods: {
        openFilterByRef(refName) {
            const drop = this.$refs.drop;
            drop.show(true);
            this.$emit('openFilterByRef', refName);
            let refItem;
            switch (refName) {
                case 'filterDate':
                    refItem = this.$refs.filterDate;
                    break;
                case 'filterReg':
                    refItem = this.$refs.filterReg;
                    break;
                case 'bcVZK':
                    refItem = this.$refs.bcVZK;
                    break;
                default: break;
            }
            setTimeout(() => refItem.$el.focus(), 100);
        },
        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        }, // сообщение
        download() {
            const that = this;
            const params = JSON.stringify({
                region: that.currRegion.code,
                year: this.planPeriod.year,
                date: moment(that.date).format('YYYY-MM-DD'),
                vzk: that.vzk,
                project: that.project,
                locale: this.$i18n.locale
            });
            let regName = that.currRegion.name_ru;
            if (this.$i18n.locale === 'kk') { regName = that.currRegion.name_kk; }
            else { regName = that.currRegion.name_ru; }
            Ax(
                {
                    url: '/api-py/scorr-plan-income-download/' + params, //  Тело файла
                    method: 'POST',
                    responseType: 'blob'
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    const name = `_${that.currRegion.code}_${regName}_${moment(that.date).format('DD.MM.YYYY')}`
                    link.setAttribute('download', this.setText('scorr_plan_inc') + `${name}.xls`);// or any other extension
                    document.body.appendChild(link);
                    link.click();
                },
                (error) => {
                    that.error = error;
                    this.progress = 0;
                }
            );

        },
        async loadData() {
            let response = [];
            try {
                const that = this;
                this.budgetForm = [];
                const params = JSON.stringify({
                    region: that.currRegion.code,
                    year: this.planPeriod.year,
                    date: moment(that.date).format('YYYY-MM-DD'),
                    vzk: that.vzk,
                    project: that.project
                });
                response = await fetch(`/api-py/scorr-plan-income/` + params);
                this.budgetForm = await response.json();
            } catch (error) {
                response = [];
                this.loading = false;
                this.makeToast(
                    "danger",
                    this.setText('errors.scorrPlanInc'),
                    this.setText('not_all_param')
                );
            }
        },
        async loadRegions() {
            try {
                const response = await fetch('/api-py/get-user-regions-by-uuid/' + store.state.user.sub);
                if (response.status === 200) {
                    this.regionBase = await response.json();
                    this.nameLocale(this.regionBase);
                    this.currRegion = this.regionBase[0];
                }
            } catch (error) {
                this.makeToast('danger', this.setText('errors.region'), error.toString());
            }
        }, // справочник регионов
        years(vue, arrYears) {
            const curYear = new Date().getFullYear();
            for (let i = curYear + 1; i >= 2023; i--) {
                const item = {year: i, name: `${i}`};
                arrYears.push(item);
                if (item.year === curYear) {
                    vue.planPeriod = item;
                }
            }
        },
        localeChange() {
            this.vzk_list[0].text = this.$t('modules.budget_execution_alteration.WITHOUT');
            this.vzk_list[1].text = this.$t('modules.budget_execution_alteration.ONLY');
            this.vzk_list[2].text = this.$t('modules.budget_execution_alteration.WITH');
        }
    },
    filters: {
        formatDate: function (value) {
            if (value) {
                const date = new Date(value);
                return date.toLocaleDateString();
            }
            return null;
        }
    }
}
</script>


<style lang="scss" scoped>
.content {
    display: flex;
    gap: 20px;
    background-color: #eef1f8;
    justify-content: space-between;
}

.content {
    height: 100%;
    .title {
        font-weight: 700;
        font-size: 14px;
        text-transform: uppercase;
        padding-left: 20px;
        margin-bottom: 26px;
    }
    .constructor,
    .forms {
        flex: 1;
    }

    .body {
        background-color: #f7f9fc;
    }

    .item {
        display: flex;
        align-items: center;
        height: 48px;
        border: 1px solid #d3dbe3;
        justify-content: space-between;
        padding: 0px 10px 0px 16px;

        &__title {
            font-weight: 500;
            font-size: 14px;
            color: #6087a0;
        }
    }

    .multiselect {
        max-width: 400px;
    }

    .footer {
        display: flex;
        height: 48px;
        align-items: center;
        justify-content: end;
        border: 1px solid #d3dbe3;
        border-top: 0;
        padding-right: 40.5%;
    }

    .vertical-line {
        width: 1px;
        background-color: #d3dbe3;
        margin-top: 40px;
    }
}
.add_modal {
    width:100%;
    height:100%;
    &__body {
        background-color: #f7f9fc;
        border: 1px solid #d3dbe3;
        border-radius: 5px;
        margin-bottom: 23px;
    }

    &__title {
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 26px;
    }

    &__item {
        display: flex;
        align-items: center;
        height: 50px;
        border: 1px solid #d3dbe3;
        justify-content: space-between;
        padding: 0px 10px 0px 16px;

        &__title {
            font-weight: 500;
            font-size: 14px;
            color: #6087a0;
        }
    }

    .input-file {
        font-weight: 600;
        font-size: 14px;
        color: #6087a0;
        &-label {
            width: 400px;
            font-weight: 600;
            font-size: 14px;
            color: #6087a0;
            cursor: pointer;
            display: flex;
            justify-self: flex-start;
        }
    }
    .form-file {
        font-weight: 600;
        font-size: 14px;
        color: #6087a0;
    }
}
.button {
    border: none;
    border-radius: 10px;
}

.button-close {
    color: red;
}
.second-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 26px;
    &__title {
        font-weight: 500;
        font-size: 14px;
    }
}

.date-picker-container {
    display: flex;
    gap: 79px;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #6087a0;

    input[type="number"] {
        border: 1px solid #bcd2e0;
        border-radius: 5px;
        padding-right: 5px;
        max-width: 80px;
        height: 28px;
        text-indent: 7px;
        text-align: left;
    }
}

</style>
